(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

/* RATING */
$(function () {
    /* UNIFORM */
    'use strict';

    var $uniformed = $('textarea, select, input[type="checkbox"], input[type="radio"]').not('.disable-uniform');
    $uniformed.uniform({
        textareaClass: 'uf-textarea',
        selectClass: 'uf-select',
        radioClass: 'uf-radio',
        checkboxClass: 'uf-checkbox'
    });

    /* BAR RATING */
    $('.barrating').each(function () {
        var $ratingSelect = $(this).find('select');
        var currentRating = $ratingSelect.data('current-rating');
        $(this).find('.current-rating .value').html(currentRating);

        $ratingSelect.barrating({
            theme: 'fontawesome-stars-o',
            showSelectedRating: false,
            initialRating: currentRating
        });

        if ($ratingSelect.attr('name') != 'doctor_rating') {
            $ratingSelect.barrating('readonly', true);
        }
    });
});

//Aktív menüpont jelzése
$(".main-menu > li > a").each(function () {
    if (this.href == window.location.href) {
        $(this).closest('li').addClass("active");
    }
});

$(".main-menu .dropdown-menu > li > a").each(function () {
    if (this.href == window.location.href) {
        $(this).parent().parent().closest('li').addClass("active");
    }
});

},{}]},{},[1]);
